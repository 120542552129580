.navigator-popover {

    .poiner {
        cursor: pointer;
    }

    .menu-item {
        cursor: pointer;
        min-height: 2.5rem;
    }

    .menu-item:hover {
        background-color: #f4f6fa;
    }



    // ::-webkit-scrollbar {
    //     width: 4px;
    //     border: 1px solid #cbcbcb;
    //     border-radius: 10px;
    //     height: 0;
    // }

    // ::-webkit-scrollbar-thumb {
    //     background: #acacac;
    //     border-radius: 10px;
    // }

}

.screen_navigator {
    .navigator-title:hover {
        cursor: pointer;
    }
}

.dropdown_navigator {
    .navigator-title {
        font-size: 16px;
    }

    .navigator-title:hover {
        cursor: pointer;
    }
}

.card {
    .bg-default {
        background: #ffe4d3;
    }

    .rounded-tx-6 {
        border-radius: 6px 6px 0 0 !important;
    }

    .rounded-6 {
        border-radius: 6px !important;
    }
}

.c-pointer {
    cursor: pointer;
}

.h_line:hover {
    border-bottom: 1px solid currentColor;
}

.btn-theme-gradient:disabled {
    background: linear-gradient(180deg, #8e8e8e, #b8b8b8);
    color: #fff !important;
}

.c-pointer {
    cursor: pointer !important;
}

.text-error {
    color: red;
}

button:disabled {
    // background: linear-gradient(180deg, #8E8E8E 0%, #B8B8B8 100%);
    // color: #fff !important;
    cursor: not-allowed !important;
}


@media (max-width: 600px) {
    .MuiMenu-list {
        display: grid;

        .MuiButtonBase-root {
            // display: block !important;
            justify-content: flex-start;
            padding: 10px !important;
        }
    }
}


@media (max-width: 800px) {
    .MuiMenu-list {
        display: grid;

        .MuiButtonBase-root {
            // display: block !important;
            justify-content: flex-start;
            padding: 10px !important;
        }
    }
}